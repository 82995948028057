var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"signup-form":""}},[_c('h2',[_vm._v("CREATE ACCOUNT")]),_c('h3',[_vm._v(_vm._s(_vm.$t('_.title')))]),_c('p',{directives:[{name:"t",rawName:"v-t:_.greeting",arg:"_",modifiers:{"greeting":true}}]}),_c('ValidationObserver',{ref:"validator",staticClass:"on-modal",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('ValidationProvider',{attrs:{"rules":"required|email|emailRegistered","name":"email","debounce":500},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{class:{error: errors.length}},[_c('label',[_vm._v(_vm._s(_vm.$t('email')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],attrs:{"type":"text","placeholder":"Email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('ValidationErrors',{attrs:{"errors":errors}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|password|includeAlphabet|includeNumber|length:8,26","name":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{class:{error: errors.length}},[_c('label',[_vm._v(_vm._s(_vm.$t('password')))]),((_vm.showPassword ? 'text' : 'password')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"placeholder":"Password","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.password)?_vm._i(_vm.password,null)>-1:(_vm.password)},on:{"change":function($event){var $$a=_vm.password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.password=$$a.concat([$$v]))}else{$$i>-1&&(_vm.password=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.password=$$c}}}}):((_vm.showPassword ? 'text' : 'password')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"placeholder":"Password","type":"radio"},domProps:{"checked":_vm._q(_vm.password,null)},on:{"change":function($event){_vm.password=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"placeholder":"Password","type":_vm.showPassword ? 'text' : 'password'},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('i',{class:{show: _vm.showPassword},on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}}),_c('ValidationErrors',{attrs:{"errors":errors}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|confirm:@password","name":"passwordConfirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{class:{error: errors.length}},[_c('label',[_vm._v(_vm._s(_vm.$t('passwordConfirm')))]),((_vm.showPassword ? 'text' : 'password')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordConfirm),expression:"passwordConfirm"}],attrs:{"placeholder":"Password","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.passwordConfirm)?_vm._i(_vm.passwordConfirm,null)>-1:(_vm.passwordConfirm)},on:{"change":function($event){var $$a=_vm.passwordConfirm,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.passwordConfirm=$$a.concat([$$v]))}else{$$i>-1&&(_vm.passwordConfirm=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.passwordConfirm=$$c}}}}):((_vm.showPassword ? 'text' : 'password')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordConfirm),expression:"passwordConfirm"}],attrs:{"placeholder":"Password","type":"radio"},domProps:{"checked":_vm._q(_vm.passwordConfirm,null)},on:{"change":function($event){_vm.passwordConfirm=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordConfirm),expression:"passwordConfirm"}],attrs:{"placeholder":"Password","type":_vm.showPassword ? 'text' : 'password'},domProps:{"value":(_vm.passwordConfirm)},on:{"input":function($event){if($event.target.composing){ return; }_vm.passwordConfirm=$event.target.value}}}),_c('i',{class:{show: _vm.showPassword},on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}}),_c('ValidationErrors',{attrs:{"errors":errors}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|lengthMin:3|charNameType|nicknameUsed","name":"nickname","debounce":500},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{class:{error: errors.length}},[_c('label',[_vm._v(_vm._s(_vm.$t('nickname')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.nickname),expression:"nickname"},{name:"placeholder",rawName:"v-placeholder:nickname",arg:"nickname"}],attrs:{"type":"text"},domProps:{"value":(_vm.nickname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.nickname=$event.target.value}}}),_c('ValidationErrors',{attrs:{"errors":errors,"placeholder":_vm.$t('_.changeNicknameAnytime')}})],1)]}}],null,true)}),_c('PrimaryButton',{class:{disabled: invalid},attrs:{"color":"red","no-gradient":""},on:{"click":_vm.next}},[_vm._v(_vm._s(_vm.$t('next')))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }