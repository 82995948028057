<template>
  <div signup-form>
    <h2>CREATE ACCOUNT</h2>
    <h3>{{ $t('_.title') }}</h3>
    <p v-t:_.greeting />

    <ValidationObserver class="on-modal" ref="validator" v-slot="{invalid}">
      <ValidationProvider rules="required|email|emailRegistered" name="email" :debounce="500" v-slot="{errors}">
        <div :class="{error: errors.length}">
          <label>{{ $t('email') }}</label>
          <input type="text" v-model="email" placeholder="Email">
          <ValidationErrors :errors="errors" />
        </div>
      </ValidationProvider>
      <ValidationProvider rules="required|password|includeAlphabet|includeNumber|length:8,26" name="password" v-slot="{errors}">
        <div :class="{error: errors.length}">
          <label>{{ $t('password') }}</label>
          <input :type="showPassword ? 'text' : 'password'" v-model="password" placeholder="Password">
          <i :class="{show: showPassword}" @click="showPassword = !showPassword"></i>
          <ValidationErrors :errors="errors" />
        </div>
      </ValidationProvider>
      <ValidationProvider rules="required|confirm:@password" name="passwordConfirm" v-slot="{errors}">
        <div :class="{error: errors.length}">
          <label>{{ $t('passwordConfirm') }}</label>
          <input :type="showPassword ? 'text' : 'password'" v-model="passwordConfirm" placeholder="Password">
          <i :class="{show: showPassword}" @click="showPassword = !showPassword"></i>
          <ValidationErrors :errors="errors" />
        </div>
      </ValidationProvider>
      <ValidationProvider rules="required|lengthMin:3|charNameType|nicknameUsed" name="nickname" :debounce="500" v-slot="{errors}">
        <div :class="{error: errors.length}">
          <label>{{ $t('nickname') }}</label>
          <input type="text" v-model="nickname" v-placeholder:nickname>
          <ValidationErrors :errors="errors" :placeholder="$t('_.changeNicknameAnytime')" />
        </div>
      </ValidationProvider>
      <PrimaryButton color="red" no-gradient @click="next" :class="{disabled: invalid}">{{ $t('next') }}</PrimaryButton>
    </ValidationObserver>
  </div>
</template>

<script>
import PrimaryButton from '@shared/components/common/PrimaryButton.vue';
import { mutate } from '@shared/utils/storeUtils';

export default {
  name: 'SignupForm',
  lexicon: 'account.signupForm',
  components: { PrimaryButton },
  data() {
    return {
      showPassword: false,
    };
  },
  computed: {
    email: mutate('signup', 'email'),
    password: mutate('signup', 'password'),
    passwordConfirm: mutate('signup', 'passwordConfirm'),
    nickname: mutate('signup', 'nickname'),
  },
  methods: {
    async next() {
      await this.timeoutShield();
      await this.$validate(this.$refs.validator);
      await this.$router.push(this.$store.state.signup.olderFourteen ? 'verification' : 'representative');
    },
  },
  async afterAsyncData({ services }) {
    if (!services.auth.allAgreeChecked) {
      throw { code: 302, to: 'membership-agreement' };
    }
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[signup-form] {
  h3 + p { .-b(#ebebf0); .mb(24); .pb(20); .tc;
    em { .bold; .c(#cc3535);}
  }
  fieldset {
    [primary-button] { .wh(100%, 56); .br(28); .fs(16); .mt(8); }
  }
  @media (@tp-up) {
    fieldset {
      [primary-button] { .mt(34); }
    }
  }
}

</style>
